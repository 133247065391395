import styled from "styled-components";
import Container from "../Container";
import FlexInColumn from "../FlexInColumn";
import FlexInRow from "../FlexInRow";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as IconCancel } from "../../Assets/Images/img-congresso-cancel.svg";
import { Image } from "antd";

const Wrapper = styled.section`
  display: grid;
  grid-template-grid: 1fr;
  gap: 2.604vw;
`;

const Title = styled.h2`
  text-align: left;
  font-size: 4.166vw;
  font-weight: bold;
  line-height: 5vw;
  font-family: "Fira Sans", sans-serif;
  letter-spacing: 0px;
  color: #c2c4d4;
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 4.444vw;
    line-height: 5.555vw;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  .item {
    align-items: center;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .item__image {
      width: 12.395vw;
    }

    .item__image.large {
      width: 30vw;
    }

    .item__image.cancel {
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      .icon {
        position: absolute;
      }
    }

    .item__title {
      font-family: "Fira Sans", sans-serif;
      color: #0c1151;
      
      font-size: 16px;
      line-height: 1.45vw;
      text-align: left;
    }

    .item__text {
      color: #000000;
      font-size: 0.937vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.25vw;
      text-align: left;
    }
  }

  .item:nth-child(odd) {
    background-color: #d6d7e2;
  }

  .item:nth-child(even) {
    background-color: #ffffff;
  }

  .slick-list,
  .slick-track {
    width: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-slider {
    overflow: hidden;
  }

  .slick-slider {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 20px;
  }

  .slick-arrow {
    position: absolute;
    bottom: 0;
    font-size: 18px;
    cursor: pointer;
  }

  .slick-prev {
    left: 19vw;
  }

  .slick-next {
    left: calc(19vw + 25px);
  }

  .slick-arrow path {
    fill: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .slick-arrow:hover path {
    fill: ${(props) => props.theme.orangeHover};
  }

  @media (min-width: 992px) {
    .item {
      padding: 1.45vw 7.292vw;
    }
  }

  @media (max-width: 992px) {
    .item {
      grid-template-columns: 1fr;
      padding: 20px;

      .item__title {
        font-size: 4.444vw;
        line-height: 5.444vw;
      }

      .item__text {
        font-size: 3.333vw;
        line-height: 4.444vw;
      }

      .item__image {
        width: auto;
        height: auto;
      }
    }
  }

  @media (min-width: 1920px) {
    .item {
      padding: 60px calc((100vw - 1640px) / 2);
    }

    .item .item__image.large {
      width: 376px;
    }
  }
`;

const SliderContent = styled.div`
  width: 40vw;

  @media (max-width: 992px) {
    width: calc(100vw - 40px);
  }
`;

export default function Congress(props) {
  const settings = {
    className: "center card-swipe",
    arrows: true,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    afterChange: function (index) {},
  };

  const ListItens = [
    {
      title: "1º Congresso Português De Anatomia Patológica",
      text: "26 outubro - 2 novembro 1968, Luanda - Angola, e Lourenço Marques - Moçambique",
      images: (
        <div className="item__image">
          <Image
            src={require("../../Assets/Images/img-congresso-05@2x.png")}
            alt="Congressos 05"
          />
        </div>
      ),
    },
    {
      title: "2º Congresso Nacional SPAP",
      text: "17-19 abril 1997, Tomar",
      images: (
        <FlexInColumn>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-06@2x.png")}
              alt="Congressos 06 - Capa"
            />
          </div>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-07@2x.png")}
              alt="Congressos 06"
            />
          </div>
        </FlexInColumn>
      ),
    },
    {
      title: "3º Congresso Nacional SPAP",
      text: "15 e 16 junho 1998, Lisboa",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-08@2x.png")}
              alt="Congressos 08"
            />
          </div>
          <SliderContent>
            <Slider {...settings}>
              <div>
                <Image
                  src={require("../../Assets/Images/img-congresso-09@2x.png")}
                  alt="Congressos 09"
                />
              </div>
              <div>
                <Image
                  src={require("../../Assets/Images/img-congresso-10@2x.png")}
                  alt="Congressos 10"
                />
              </div>
              <div>
                <Image
                  src={require("../../Assets/Images/img-congresso-11@2x.png")}
                  alt="Congressos 11"
                />
              </div>
              <div>
                <Image
                  src={require("../../Assets/Images/img-congresso-12@2x.png")}
                  alt="Congressos 12"
                />
              </div>
            </Slider>
          </SliderContent>
        </FlexInRow>
      ),
    },
    {
      title: "4º Congresso Nacional SPAP + 7ª Reunião SPC",
      text: "8-10 abril 1999, Cascais",
      images: (
        <div className="item__image">
          <Image
            src={require("../../Assets/Images/img-congresso-13@2x.png")}
            alt="Congressos 13"
          />
        </div>
      ),
    },
    {
      title: "5º Congresso Nacional SPAP",
      text: "6-8 abril 2000, Figueira da Foz",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-14@2x.png")}
              alt="Congressos 14"
            />
          </div>
          <FlexInColumn>
            <div className="item__image">
              <Image
                src={require("../../Assets/Images/img-congresso-15@2x.png")}
                alt="Congressos 15"
              />
            </div>
            <div className="item__image">
              <Image
                src={require("../../Assets/Images/img-congresso-16@2x.png")}
                alt="Congressos 16"
              />
            </div>
          </FlexInColumn>
        </FlexInRow>
      ),
    },
    {
      title: "6º Congresso Nacional SPAP + IX Reunião Anual SPC",
      text: "9-12 maio 2001, Póvoa de Varzim",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-17.jpg")}
              alt="Image Congresso 17"
            />
          </div>
          <FlexInColumn>
            <div className="item__image">
              <Image
                src={require("../../Assets/Images/img-congresso-18.jpg")}
                alt="Image Congresso 18"
              />
            </div>
            <div className="item__image">
              <Image
                src={require("../../Assets/Images/img-congresso-19.jpg")}
                alt="Image Congresso 19"
              />
            </div>
          </FlexInColumn>
        </FlexInRow>
      ),
    },
    {
      title: "7º Congresso Nacional SPAP + X Reunião Anual SPC",
      text: "11-13 abril 2002, Aveiro",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-20.jpg")}
              alt="Image Congresso 20"
            />
          </div>
          <FlexInColumn>
            <div className="item__image">
              <Image
                src={require("../../Assets/Images/img-congresso-21.jpg")}
                alt="Image Congresso 21"
              />
            </div>
          </FlexInColumn>
        </FlexInRow>
      ),
    },
    {
      title: "8º Congresso Nacional SPAP",
      text: "3-5 abril 2003, Évora",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-22.jpg")}
              alt="Image Congresso 22"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "9º Congresso Nacional SPAP + XII Reunião Anual SPC",
      text: "22-24 abril 2004, Luso",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-23.jpg")}
              alt="Image Congresso 23"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "10º Congresso Nacional SPAP + XIII Reunião Anual SPC",
      text: "21-23 abril 2005, Porto",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-24.jpg")}
              alt="Image Congresso 24"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "11º Congresso Nacional SPAP",
      text: "20-22 abril 2006, Luso",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-25.jpg")}
              alt="Image Congresso 25"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "12º Congresso Nacional SPAP + XV Reunião Anual SPC",
      text: "3-5 maio 2007, Coimbra",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-26.jpg")}
              alt="Image Congresso 26"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "13º Congresso Nacional SPAP",
      text: "24-25 abril 2009, Lisboa",
      images: (
        <FlexInRow>
          <div className="item__image large">
            <Image
              src={require("../../Assets/Images/img-congresso-27.jpg")}
              alt="Image Congresso 27"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "14º Congresso SPAP",
      text: "7-9 abril 2011, Porto",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-28.jpg")}
              alt="Image Congresso 28"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "15º Congresso SPAP + XVIII Congresso SPC",
      text: "14-15 maio 2015, Évora",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-29.jpg")}
              alt="Image Congresso 29"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "16º Congresso SPAP + 19º Congresso SPC",
      text: "25-27 maio 2017, Troia",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-30.jpg")}
              alt="Image Congresso 30"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "17º Congresso SPAP + 20º Congresso SPC",
      text: "19-21 abril 2018, Figueira da Foz",
      images: (
        <FlexInRow>
          <div className="item__image">
            <Image
              src={require("../../Assets/Images/img-congresso-31.jpg")}
              alt="Image Congresso 31"
            />
          </div>
        </FlexInRow>
      ),
    },
    {
      title: "18º Congresso SPAP + 21º Congresso SPC",
      text: "17-19 novembro 2021, Lisboa",
      images: (
        <FlexInRow>
          <FlexInColumn>
            <picture className="item__image cancel">
              <source
                srcSet={require("../../Assets/Images/img-congresso-32.jpg")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../Assets/Images/img-congresso-32.jpg")}
                alt="Image Congresso 32"
              />
              <IconCancel className="icon icon-cancel" />
            </picture>
            <div className="item__image">
              <Image
                src={require("../../Assets/Images/img-congresso-33.jpg")}
                alt="Image Congresso 33"
              />
            </div>
          </FlexInColumn>
        </FlexInRow>
      ),
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Title>Congressos</Title>
      </Container>
      <Grid>
        {ListItens.map((item, index) => {
          return (
            <div className={"item item-" + index}>
              <div className="item__content">
                <h4 className="item__title">{item.title}</h4>
                <h5 className="item__text">{item.text}</h5>
              </div>
              {item.images}
            </div>
          );
        })}
      </Grid>
    </Wrapper>
  );
}
