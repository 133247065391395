import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Card from "../Card";
import Title from "../Title";
import { ReactSortable } from "react-sortablejs";
import {
  Button,
  Divider,
  Space,
  Switch,
  Upload,
  Input,
  Drawer,
  Col,
  Modal,
  Row,
  DatePicker,
  notification,
  Flex,
  Tag,
} from "antd";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import {
  CloseCircleOutlined,
  SearchOutlined,
  MailOutlined,
} from "@ant-design/icons";
import axios, { all } from "axios";

const { RangePicker } = DatePicker;

const WrapperCardList = styled.section`
  // display: flex;
  width: 100% !important;
  flex-direction: row;

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0% !important;
    text-align: left;
  }

  .p-desc {
    font-size: 15px;
    color: #000;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .text-small {
    color: #809fb8;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .css-dev-only-do-not-override-j0nf2s .text {
    margin-bottom: 0 !important;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  #input-search {
    .ant-input-suffix {
      background: none;
    }
  }
`;

const WrapperCardEvento = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .icon {
    width: 25px;
  }

  .size-eye svg {
    width: 25px;
  }

  .ant-upload-btn {
  min-height: 190px;
}

  .ant-radio-button-wrapper {
    height: 45px;
    border: 1px solid rgb(128, 159, 184);
  }

  .ant-radio-button-wrapper span {
    line-height: 45px; 
    font-size: 14px;s
  }
  .text {
    margin-bottom: 0% !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }
  

  .text {
    color: #809FB8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .active {
    background: lightblue;
  }

  .center {
    text-align: center;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
    height: -webkit-fill-available;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .div-sortable .ant-col {
    padding: 5px;
  }

  .div-evento{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i { margin: 0 3px; }

    a { text-decoration: none; }
  }

  


  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #FFF;
    }
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

const CardHeader = styled.div`
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: auto auto;

  .ps-end {
    place-self: start end;
  }
`;

export default function CardQuotes(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [dataFilter, setDataFilter] = useState([]);
  const [showEventDrawer, setShowEventDrawer] = useState(false);
  const [nextYear, setNextYear] = useState('');

  const dateFormat = "DD/MM/YYYY";
  const rangeFormat = "DD/MM/YYYY HH:mm";
  const [allItems, setAllItems] = useState([]);

  const [listItems, setListItems] = useState([]);

  const quillRef = useRef(null);

  /* Values novos */
  const [emailSend, setEmailSend] = useState(false);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState([]);
  const [emailDate, setEmailDate] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");

  /* Values Payments  */
  const [paymentAcceptMultibanco, setPaymentAcceptMultibanco] = useState(false);
  const [paymentAcceptMBWay, setPaymentAcceptMBWay] = useState(false);
  const [paymentAcceptTransferenciaBancaria, setPaymentAcceptTransferenciaBancaria] =
    useState(false);

  const [paymentValueSPAP, setPaymentValueSPAP] = useState("");
  const [paymentValueESP, setPaymentValueESP] = useState("");
  const [paymentValueNISPAP, setPaymentValueNISPAP] = useState("");
  const [paymentValueNIESP, setPaymentValueNIESP] = useState("");

  /* Values */
  const [isNISPAPUsers, setIsNISPAPUsers] = useState(false);
  const [isSPAPUsers, setIsSPAPUsers] = useState(false);
  const [isAllUsers, setIsAllUsers] = useState(true);

  // editar plano
  const [editId, setEditId] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editYear, setEditYear] = useState("");
  const [editDate, setEditDate] = useState([]);
  const [editPaymentAcceptMultibanco, setEditPaymentAcceptMultibanco] =
    useState(false);
  const [editPaymentAcceptMBWay, setEditPaymentAcceptMBWay] = useState(false);
  const [editPaymentAcceptTransferenciaBancaria, setEditPaymentAcceptTransferenciaBancaria] =
    useState(false);
  const [editPaymentValueSPAP, setEditPaymentValueSPAP] = useState("");
  const [editPaymentValueESP, setEditPaymentValueESP] = useState("");

  const [removeEventModalOpen, setRemoveEventModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);

  const [disabledCard, setDisabledCard] = useState(false);


  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = () => {
    fetch(`https://api.spap.pt/api/all-plans`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar planos");
        }
        return response.json();
      })
      .then((data) => {
        let maxValue = 0;
        console.log(data.plans);
        data.plans.map((el) => {
          const valueFromObject = el.year;
          maxValue = Math.max(maxValue, valueFromObject);
          let currentMonth = new Date().getMonth();
          let currentYear = new Date().getFullYear();
          el.current = false;
          el.past = false;
          if ((el.year == currentYear && currentMonth != 11)) {
            el.current = true;
          }
          if ((el.year == (currentYear + 1) && currentMonth == 11)) {
            el.current = true;
          }
          if ((el.year == currentYear && currentMonth == 11) 
            || el.year < currentYear) {
            el.past = true;
          }
        });
        let nextYear = Number(maxValue) + 1;

        data.plans.sort((a, b) => (b.year - a.year));

        
        setNextYear(nextYear);
        setAllItems(data.plans);
      })
      .catch((error) => console.error(error));
  }

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const showModal = (e) => {
    setQuotaToUpdate(e);
    setIsModalOpen(true);
  };

  const clickToEventDrawer = (item) => {
    setEditId(item.id);
    setEditTitle(item.title);
    setEditYear(item.year);
    setEditPaymentAcceptMultibanco(item.accept_multibanco);
    setEditPaymentAcceptMBWay(item.accept_mbway);
    setEditPaymentAcceptTransferenciaBancaria(item.accept_bank_transfer);
    setEditPaymentValueSPAP(item.spap);
    setEditPaymentValueESP(item.esp);

    setShowEventDrawer(true);
  };

  useEffect(() => {
    if (showEventDrawer && quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      quillEditor.setText("");
      quillEditor.clipboard.dangerouslyPasteHTML(-0, descricaoEventEdit);
    }
  }, [showEventDrawer]);

  const [confModalVisible, setModalVisible] = useState(false);

  const confHandleOk = () => {
    setModalVisible(false);
    handleQuote();
  };

  const confHandleCancel = () => {
    setModalVisible(false);
  };

  const handleQuoteConfirm = (e) => {
      handleQuote();
  };

  const handleQuote = (e) => {
    if (
      title.length == 0 ||
      paymentValueSPAP.length == 0 ||
      nextYear.length == 0 ||
      paymentValueESP.length == 0
    ) {
      notification.error({
        description:
          "Para criar um novo plano/evento, preencha todos os campos.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("title", title);
    formData.append("year", nextYear);

    formData.append("paymentAcceptMultibanco", paymentAcceptMultibanco ? 1 : 0);
    formData.append("paymentAcceptMBWay", paymentAcceptMBWay ? 1 : 0);
    formData.append(
      "paymentAcceptTransferenciaBancaria",
      paymentAcceptTransferenciaBancaria ? 1 : 0
    );
    formData.append("paymentValueSPAP", paymentValueSPAP);
    formData.append("paymentValueESP", paymentValueESP);

    // You can use any AJAX library you like
    fetch("https://api.spap.pt/api/admin/store-plan", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setTitle("");
            setTitle("");
            setPaymentAcceptMultibanco(false);
            setPaymentAcceptMBWay(false);
            setPaymentAcceptTransferenciaBancaria(false);
            setPaymentValueSPAP("");
            setPaymentValueESP("");
            fetchPlans();
            notification.success({
              description: "O plano/evento foi criado com sucesso!",
            });
          })
          .catch((err) => {});
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  const handleEditEvent = () => {
    // if (false) {
    //   notification.error({
    //     description: "Para editar o plano, preencha os campos respetivos.",
    //   });
    //   return;
    // }

    const formData = new FormData();

    formData.append("id", editId);
    formData.append("title", editTitle);

    console.log(editYear);
    formData.append("ano", editYear);

    formData.append(
      "paymentAcceptMultibanco",
      editPaymentAcceptMultibanco ? 1 : 0
    );
    formData.append("paymentAcceptMBWay", editPaymentAcceptMBWay ? 1 : 0);
    formData.append(
      "paymentAcceptTransferenciaBancaria",
      editPaymentAcceptTransferenciaBancaria ? 1 : 0
    );

    formData.append("paymentValueSPAP", editPaymentValueSPAP);
    formData.append("paymentValueESP", editPaymentValueESP);

    fetch("https://api.spap.pt/api/admin/update-plan", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setCurrentItemsEvent(allItems.slice(itemOffsetEvent, endOffset));
          })
          .catch((err) => {});
      })
      .then(() => {
        notification.success({
          description: "O plano foi atualizado com sucesso!",
        });
        setShowEventDrawer(false);

        fetchPlans();
      })
      .catch(() => {
        notification.error({
          description: "Já existe um plano com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const onChangeIsDisable = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://api.spap.pt/api/admin/set-disable",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        session_storage.all_data.plans = res.data.quotes;
        localStorage.setItem("session_data", JSON.stringify(session_storage));
        handleEventos(res.data.eventos);
      })
      .catch((error) => {});
  };

  const onChangePaymentAcceptMultibanco = (checked) => {
    checked
      ? setPaymentAcceptMultibanco(true)
      : setPaymentAcceptMultibanco(false);
  };

  const onChangePaymentAcceptMBWay = (checked) => {
    checked ? setPaymentAcceptMBWay(true) : setPaymentAcceptMBWay(false);
  };

  const onChangePaymentAccepTransferenciaBancaria = (checked) => {
    checked
      ? setPaymentAcceptTransferenciaBancaria(true)
      : setPaymentAcceptTransferenciaBancaria(false);
  };

  const onChangePaymentAcceptMultibancoEdit = (checked) => {
    checked
      ? setEditPaymentAcceptMultibanco(true)
      : setEditPaymentAcceptMultibanco(false);
  };

  const onChangePaymentAcceptMBWayEdit = (checked) => {
    checked
      ? setEditPaymentAcceptMBWay(true)
      : setEditPaymentAcceptMBWay(false);
  };

  const onChangePaymentAccepTransferenciaBancariaEdit = (checked) => {
    checked
      ? setEditPaymentAcceptTransferenciaBancaria(true)
      : setEditPaymentAcceptTransferenciaBancaria(false);
  };

  const handleRemoveEvent = () => {
    axios
      .post(
        "https://api.spap.pt/api/admin/remove-plan",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveEventModalOpen(false);
        handleEventos(res.data.quotes);
        notification.success({
          description: "O plano foi removido com sucesso!",
        });
      })
      .catch((error) => {
        notification.error({
          description: "Erro ao remover o plano! Tente novamente.",
        });
      });
  };

  const openRemoveEventModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveEventModalOpen(true);
  };
  /* CAMPO DE BUSCA */
  const [valueSearch, setValueSearch] = useState("");

  const handleSearchChange = (e) => {
    setValueSearch(e.target.value);

    actionFilterItens(e.target.value);
  };

  function actionFilterItens(text) {
    let filteredArray = allItems;
    let filteredItems = filteredArray.filter((item) => {
      return item.title.toLowerCase().includes(text.toLowerCase()) || item.year.toLowerCase().includes(text.toLowerCase());
    });

    handleEventos(filteredItems);
  }

  const handleSearchClear = () => {
    setValueSearch("");
    handleEventos(allItems);
  };

  return (
    <WrapperCardEvento>
      <Card
      >
        <CardHeader>
          <Space direction="vertical" size={60}>
            <Title>Criar novo plano de quotas anual</Title>
          </Space>
          {/* <Space>
            <Row align={'middle'}>
              <MailOutlined style={{color: '#809FB8'}}/>
            </Row>
            <span style={{ fontSize: "14px" }}>Email</span>
            <Switch
              key={"is-event"}
              onChange={(e) => setEmailSend(e)}
              checked={emailSend}
            />
          </Space> */}
        </CardHeader>
        <Divider />
        <Row gutter={[12, 18]} align="bottom">
          <Col span={16}>
            <label className="text">Nome do Plano</label>
            <Input
              key={"titulo-event"}
              placeholder="Título"
              name="titulo"
              style={styles.input_class}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <label className="text">Ano do plano</label>
            <Input
              style={styles.input_class}
              disabled
              value={
                nextYear
              }
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: "10px" }} align="bottom">
          <Col span={12}>
            <label className="text">Tipo de pagamentos aceites</label>
            <Row
              style={{
                backgroundColor: "#F4F9FB",
                borderRadius: "12px",
                border: "1px dashed #809FB8",
                height: "134px",
                marginBottom: "20px",
                padding: "25px",
              }}
            >
              <Col span={24}>
                <Space size={20}>
                  <Switch
                    onChange={(e) => onChangePaymentAcceptMultibanco(e)}
                    checked={paymentAcceptMultibanco}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0C1151",
                    }}
                  >
                    Multibanco
                  </span>
                </Space>
              </Col>
              <Col span={24}>
                <Space size={20}>
                  <Switch
                    onChange={(e) => onChangePaymentAcceptMBWay(e)}
                    checked={paymentAcceptMBWay}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0C1151",
                    }}
                  >
                    MbWay
                  </span>
                </Space>
              </Col>
              <Col span={24}>
                <Space size={20}>
                  <Switch
                    onChange={(e) => onChangePaymentAccepTransferenciaBancaria(e)}
                    checked={paymentAcceptTransferenciaBancaria}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0C1151",
                    }}
                  >
                    Transferência Bancária
                  </span>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[12, 18]}>
              <Col span={24}>
                <Row>
                  <Col span={24} style={{ marginBottom: "20px" }}>
                    <label className="text">
                      Valor em € (euros) Quota SPAP
                    </label>
                    <Input
                      key={"payment-spap"}
                      placeholder="Valor (€)"
                      name="payment-spap"
                      style={styles.input_class}
                      value={paymentValueSPAP}
                      onChange={(e) => setPaymentValueSPAP(e.target.value)}
                    />
                  </Col>
                  <Col span={24} style={{ marginBottom: "20px" }}>
                    <label className="text">
                      Valor em € (euros) Quota ESP
                    </label>
                    <Input
                      key={"payment-esp"}
                      placeholder="Valor (€)"
                      name="payment-esp"
                      style={styles.input_class}
                      value={paymentValueESP}
                      onChange={(e) => setPaymentValueESP(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0" }} />
        <Row>
          <Col span={16}>
            {/* <Space size={20}>
              <Space size={5}>
                <Switch 
                  onChange={(e) => onChangeIsNISPAPUsers(e)} 
                  checked={isNISPAPUsers} 
                />
                <span style={{ fontSize: "14px" }}>NISPAP</span>
              </Space>
              <Space>
                <Switch 
                  onChange={(e) => onChangeIsSPAPUsers(e)} 
                  checked={isSPAPUsers} 
                />
                <span style={{ fontSize: "14px" }}>SPAP</span>
              </Space>
              <Space>
                <Switch
                  checked={isAllUsers}
                  onChange={(e) => onChangeIsAllUsers(e)}
                />
                <span style={{ fontSize: "14px" }}>Todos os Sócios</span>
              </Space>
            </Space> */}
          </Col>
          <Col span={8}>
            <Row align={"end"}>
              <Button
                onClick={handleQuoteConfirm}
                type="primary"
                className="cta publish"
                align="end"
              >
                Guardar <IconOk className="icon icon-ok" />
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>

      {/* card list */}
      <WrapperCardList>

        <Row gutter={[12,12]}>

        {allItems?.length > 0 && 
              allItems.map((item, index) => {
                // let textPreview = item.descricao.replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>").slice(0, 120);
                let textPreview =
                  "Texto de corpo de email: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.";
                if (textPreview?.length > 120) textPreview += " (...)";
                return (
                  <Col span={12}>
                    <Card
                      key={"eventos-" + item.id}
                      className={ (item.current == true) ? "div-evento active" : "div-evento"}
                      style={{ height: "100%" }}
  
                    >
                      <div>
                        <CardHeader>
                          <Title>{item.title}</Title>
                          { item.past == true && 
                            <Tag style={{ width: 'min-content', fontSize: '14px' }}>Plano passado</Tag>
                          }
                          { item.current == true && 
                            <Tag style={{ width: 'min-content', fontSize: '14px' }}>Plano atual</Tag>
                          }
                          { item.past != true && item.current != true && 
                          <IconClose
                            className="icon icon-close ps-end"
                            onClick={() =>
                              openRemoveEventModal(item.id, item.title)
                            }
                          />}
                        </CardHeader>
                        <p className="p-desc"><b>Quota SPAP</b>: {item.spap}€ | <b>Quota ESP</b>: {item.esp}€</p>
                      </div>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space align="center" size={10}>
                              <Tag style={{ fontSize: '14px'}}>{item.year}</Tag>
                              <Flex gap="4px 0" wrap>
                                {item.accept_bank_transfer == 1 &&<Tag color="#2db7f5">Transferência Bancária</Tag>}
                                {item.accept_mbway == 1 &&<Tag color="red">MBWay</Tag>}
                                {item.accept_multibanco == 1 &&<Tag color="#108ee9">Multibanco</Tag>}
                              </Flex>
                            </Space>
                            <div
                              size={0}
                              className="action-edit"
                              key={"evento-" + index}
                              onClick={() => clickToEventDrawer(item)}
                            >
                              <IconEdit className="icon icon-edit" />
                            </div>
                          </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
        </Row>
        <Drawer
          key={"editar-evento-editDrawer"}
          title="Editar Plano"
          width={700}
          onClose={() => setShowEventDrawer(false)}
          open={showEventDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={
            <Space>
              <Button onClick={() => setShowEventDrawer(false)}>
                Cancelar
              </Button>
              <Button
                onClick={handleEditEvent}
                type="submit"
                style={{
                  borderRadius: "6px",
                  background: "#494D7D",
                  color: "#fff",
                }}
              >
                Guardar
              </Button>
            </Space>
          }
        >
          <form layout="vertical" id="update-event-form">
         
                <Row gutter={[12, 18]} align="bottom">
                  <Col span={20}>
                    <label className="text">Nome</label>
                    <Input
                      key={"titulo-event"}
                      placeholder="Título"
                      name="titulo"
                      style={styles.input_class}
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                    />
                  </Col>
                  <Col span={4}>
                    <label className="text">Ano</label>
                    <Input
                      key={"titulo-event"}
                      placeholder="Título"
                      name="titulo"
                      style={styles.input_class}
                      value={editYear}
                      disabled
                    />
                  </Col>
                </Row>
                <Divider />
                <Row  gutter={[12, 18]} style={{ marginTop: "10px" }} align="bottom">
                  <Col span={12}>
                    <label className="text">
                      Valor em € (euros) Quota SPAP
                    </label>
                    <Input
                      key={"payment-spap"}
                      placeholder=""
                      name="payment-spap"
                      style={styles.input_class}
                      value={editPaymentValueSPAP}
                      onChange={(e) =>
                        setEditPaymentValueSPAP(e.target.value)
                      }
                    />
                    </Col>
                  <Col span={12}>
                    <label className="text">
                      Valor em € (euros) Quota ESP
                    </label>
                    <Input
                      key={"payment-esp"}
                      placeholder=""
                      name="payment-esp"
                      style={styles.input_class}
                      value={editPaymentValueESP}
                      onChange={(e) =>
                        setEditPaymentValueESP(e.target.value)
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <label className="text">Tipo de pagamentos aceites</label>
                    <Row
                      style={{
                        backgroundColor: "#F4F9FB",
                        borderRadius: "12px",
                        border: "1px dashed #809FB8",
                        height: "134px",
                        marginBottom: "20px",
                        padding: "25px",
                      }}
                    >
                      <Col span={24}>
                        <Space size={20}>
                          <Switch
                            onChange={(e) =>
                              onChangePaymentAcceptMultibancoEdit(e)
                            }
                            checked={
                              editPaymentAcceptMultibanco != 0 ? true : false
                            }
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#0C1151",
                            }}
                          >
                            Multibanco
                          </span>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space size={20}>
                          <Switch
                            onChange={(e) => onChangePaymentAcceptMBWayEdit(e)}
                            checked={editPaymentAcceptMBWay != 0 ? true : false}
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#0C1151",
                            }}
                          >
                            MbWay
                          </span>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space size={20}>
                          <Switch
                            onChange={(e) =>
                              onChangePaymentAccepTransferenciaBancariaEdit(e)
                            }
                            checked={
                              editPaymentAcceptTransferenciaBancaria != 0 ? true : false
                            }
                          />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#0C1151",
                            }}
                          >
                            Transferência Bancária
                          </span>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
          </form>
        </Drawer>

        <Modal
          key={"remove-quote-" + idToRemove}
          title="Está prestes a eliminar um plano/evento"
          open={removeEventModalOpen}
          onOk={handleRemoveEvent}
          onCancel={() => setRemoveEventModalOpen(false)}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende remover o plano/evento{" "}
            <b>'{titleToRemove}'</b>?
          </p>
        </Modal>

        <Modal
          title="Confirmação"
          visible={confModalVisible}
          onOk={confHandleOk}
          onCancel={confHandleCancel}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>Você tem certeza que deseja executar esta ação?</p>
        </Modal>
      </WrapperCardList>
    </WrapperCardEvento>
  );
}
