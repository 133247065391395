import styled from "styled-components";

import Container from "../../../Components/Container";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import FlexInColumn from "../../../Components/FlexInColumn";
import FlexInRow from "../../../Components/FlexInRow";
import ContactForm from "../../../Components/ContactForm";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  notification,
  Upload,
  message,
  Space,
  Form,
  Input,
  Spin,
  Dropdown,
  Tooltip,
  Empty,
  Modal,
  Select,
  Tag,
  Switch,
  Carousel,
  Divider,
} from "antd";
import {
  PlusOutlined,
  MessageOutlined,
  EditOutlined,
  PieChartOutlined,
  DatabaseOutlined,
  LinkOutlined,
  SettingOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  UploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import axios from "axios";
import { useForm } from "react-hook-form";
import Search from "antd/es/input/Search";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    margin-bottom: 2.188vw;
  }

  .fl-right {
    float: right !important;
  }

  .t-wrap {
    text-wrap: auto !important;
  }

  .card-details {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .bookname {
    margin-top: 10px !important;
    font-size: 23px;
    color: #000;
  }

  .bookdetails {
    margin-top: 10px !important;
    margin-left: 20px;
    font-size: 16px;
    margin-bottom: 0;
    color: #000;
  }

  .search-input .ant-input-wrapper {
    
  }

  .edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-slider button {
    color:rgb(46,112,146) !important;
  }
 
  .slick-dots:not(.slick-active) button {
    background: #000 !important;
  }
  .slick-dots .slick-active button {
    background: rgb(102, 153, 180) !important;
  }

  .span-switch {
    color: #000;
  }

  .carousel .slick-list, .slick-track {
    margin: 0 !important;
    
  }

  .ant-divider {
    margin: 8px 0;
  }

  form .ant-card-head {
    background: #2e70921c;
  }

  .ant-card-cover img {
    height: 300px !important;
    object-fit: contain;
    object-
  }

  .ant-card-cover {
    border-bottom: 1px solid #cccccc5c;
  }

  .ant-form-item { 
    margin-bottom: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-select {
    height: 40px !important;
  }

  .pt-10 .ant-card-body {
    padding-top: 10px !important;
    padding-bottom: 5px !important;
  }

  .absolute-tag {
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 999 !important;
  }

  .absolute-tag .ant-tag {
    float:right !important;
  }

  .ant-upload {
    width: 100% !important;
  }

  .select-type .ant-select-selector {
    height: 40px !important;
    max-height: 40px !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .b-blue {
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .ant-switch:not(.ant-switch-checked) .ant-switch-inner {
    background: #ffffff8f !important;
  }

  .ant-switch:not(.ant-switch-checked) .ant-switch-handle:before {
    background: #ccc !important;
  }

  .ant-switch-checked .ant-switch-inner {
    background: #a9afb29c !important;
  }
  
  .ant-switch-checked .ant-switch-handle:before {
    background: rgb(102, 153, 180) !important;
  }

  textarea {
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .text-card {
    text-align: left !important;
    padding: 10px 0;
  }

  .text-card h6 {
    font-size: 17px;
    color: rgb(46,112,146);
  }
  .text-card p {
    font-size: 15px;
  }

  .new-btn {
    background: rgb(102, 153, 180);
    border: 1px solid rgb(102, 153, 180) !important;
    color: #fff;
    padding: 0 20px;
  }

  .h-40, .search-input input, .search-input .ant-btn {
    height: 40px !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .see-more-btn:hover {
    color: rgb(102, 153, 180) !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .new-btn:hover {
    color: rgb(102, 153, 180) !important;
    background: #fff;
  }

  .ant-tabs-tab {
    padding: 12px 20px;
  }

  .cancel {
    border: 1px solid #ccc !important;
    color: #000 !important;
  }

  .sidebar__image {
    margin-left: auto;
    max-width: 27.917vw;
  }

  img {
    width: 100%;
  }

  a {
    color: #000000;
    place-self: flex-start;
    text-align: left;
    text-decoration: none;
    transition: 0.3s ease;

    :hover {
      color: ${(props) => props.theme.blueHover};
    }
  }

  @media (max-width: 992px) {

    .bookdetails {
      margin-left: 5px !important;
    }

    form .ant-card-head {
      padding-bottom: 10px !important;
    }
    margin-top: 120px;

    a {
      font-size: 3.333vw;
      line-height: 4.444vw;
      place-self: center;
    }

    .sidebar__image {
      margin: auto;
      max-width: 80%;
    }
  }
`;

export default function Livros(props) {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const navigate = useNavigate();
  const [bookId, setbookId] = useState("");
  const [isbn, setISBN] = useState("");
  const [updatedFileList, setUpdatedFileList] = useState([]);
  const [selectedBook, setSelectedBook] = useState("");
  const [myBooks, setMyBooks] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [newBook, setNewBook] = useState(false);
  const [updateBook, setUpdateBook] = useState(false);
  const [showBook, setShowBook] = useState(false);
  const [bookToUpdate, setBookToUpdate] = useState({});
  const [isbnData, setIsbnData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [bookstoShow, setBooksToShow] = useState([]);
  const [bookToShow, setBookToShow] = useState([]);
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [open, setOpen] = useState(false);
  const [templateSelected, setTemplateSelected] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [radioSelected, setRadioSelected] = useState("");
  const [allTemplates, setAllTemplates] = useState([]);
  const [bookKey, setDataBookKey] = useState([]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });


  const submitForm = async (formData) => {
    const formNewData = new FormData();
    formNewData.append("name", formData.name);
    formNewData.append("author", formData.author);
    formNewData.append("user_id", session_storage.all_data.user_session.id);
    formNewData.append("type", formData.type);
    formNewData.append("description", formData.description);
    formNewData.append("email", formData.email);
    formNewData.append("phone", formData.phone);
    formNewData.append("editor", formData.editor);
    formNewData.append("publish_date", formData.publish_date);
    formNewData.append("isbn", isbn);

    if (formData.image[0] != undefined) {
      formNewData.append("image1", formData.image[0].originFileObj);
    }
    if (formData.image[1] != undefined) {
      formNewData.append("image2", formData.image[1].originFileObj);
    }
    if (formData.image[2] != undefined) {
      formNewData.append("image3", formData.image[2].originFileObj);
    }
    try {
      const response = await axios.post("https://api.spap.pt/api/book/create",
        formNewData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      );
      form.resetFields();
      setNewBook(false);
      notification.success({
        message: `SUCESSO!`,
        description: "A publicação do seu livro foi criada com sucesso.",
      });
      fetchBooks();
    } catch (error) {
      console.log(error);
      notification.error({
        message: `ERRO!`,
        description: "Houve problemas a criar a sua publicação. Tente novamente.",
      });
    }
  };

  const submitUpdateForm = async (formData) => {
    const formNewData = new FormData();
    formNewData.append("id", formData.id);
    formNewData.append("name", formData.nameToUpdate);
    formNewData.append("author", formData.author);
    formNewData.append("user_id", session_storage.all_data.user_session.id);
    formNewData.append("type", formData.type);
    formNewData.append("description", formData.description);
    formNewData.append("email", formData.email);
    formNewData.append("phone", formData.phone);
    formNewData.append("editor", formData.editor);
    formNewData.append("publish_date", formData.publish_date);
    formNewData.append("isbn", formData.isbn);
    if (formData.image[0] != undefined) {
      formNewData.append("image1", formData.image[0].originFileObj);
    }
    if (formData.image[1] != undefined) {
      formNewData.append("image2", formData.image[1].originFileObj);
    }
    if (formData.image[2] != undefined) {
      formNewData.append("image3", formData.image[2].originFileObj);
    }
    try {
      const response = await axios.post("https://api.spap.pt/api/book/update",
        formNewData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      );
      form.resetFields();
      updateForm.resetFields();
      setUpdateBook(false);
      setBookToUpdate({});
      notification.success({
        message: `SUCCESS!`,
        description: "A publicação do seu livro foi atualizada com sucesso.",
      });
      fetchBooks();
    } catch (error) {
      console.log(error);
      notification.error({
        message: `ERROR!`,
        description: "Houve problemas a atualizar a sua publicação. Tente novamente.",
      });
    }
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };


  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  const onCancel = () => {
    setNewBook(false);
  };

  const onCancelUpdate = () => {
    updateForm.resetFields();
    setBookToUpdate({});
    setUpdateBook(false);
  };

  const showDrawer = (user) => {
    console.log(user);
    setCurrentUser(user);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    fetchBooks();
    fetchTemplates();
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(config.server_ip + endpoints.flow.read);
      let templates = response.data[0].filter((flow) => flow.is_template == 1);
      setAllTemplates(templates);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBooks = async () => {
    try {
      const response = await axios.get("https://api.spap.pt/api/book/all", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + session_storage.token,
        },
      });
      console.log(response.data);
      handleBooks(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
    fetchTemplates();
  }, []);

  const handleBooks = (data) => {
    let allBooks = data.books;
    const allUsers = data.users;

    allBooks.map((book) => {
      if (book.user_id) {
        let user = allUsers.filter((user) => {
          if (book.user_id != user.id) {
            return false;
          }
          return true;
        })[0];
        book.userName = user.name;
        
        if (user?.apelido && user.apelido.length > 0) {
          book.userName += ' ';
          book.userName += user?.apelido;
        }
      }
    });

    setUsers(allUsers);
    // user[0].username.match(/\b(\w)/g).join()
    setBooks(allBooks);
    setBooksToShow(allBooks);
  };

  const handleSearch = (input, select, myBooks) => {
    console.log(myBooks);
    input = input.toLowerCase();
    if (input.length == 0 && select.length == 0) {
      if (myBooks == true) {
        let mine = books.filter((book) => {
          if (book.user_id == session_storage.all_data.user_session.id) {
            return true;
          }
        });
        setBooksToShow(mine);
        setSelectedBook(input);
        setSelectedType(select);
        setMyBooks(myBooks);
        return;
      } else {
        setBooksToShow(books);
        setSelectedBook(input);
        setSelectedType(select);
        return;
      }
    }

    let newbooks = books.filter((book) => {
      let returnSelect = true;
      let returnInput = true;
      let returnMyBooks = true;
      if (select.length > 0 && book.type != select) {
        returnSelect = false;
      }
      if (input.length > 0 && !book.name?.toLowerCase().includes(input)) {
        returnInput = false;
      }
      if (myBooks == true && book.user_id != session_storage.all_data.user_session.id) {
        returnMyBooks = false;
      }

      if (returnSelect && returnInput && returnMyBooks) {
        return true;
      }

    });

    setSelectedBook(input);
    setSelectedType(select);
    setMyBooks(myBooks);

    setBooksToShow(newbooks);
  };

  const posts = [
    {
      id: 1,
      title: "Post 1",
      description: "Descrição do Post 1",
      image: "https://via.placeholder.com/300",
    },
    {
      id: 2,
      title: "Post 2",
      description: "Descrição do Post 2",
      image: "https://via.placeholder.com/300",
    },
    {
      id: 3,
      title: "Post 3",
      description: "Descrição do Post 3",
      image: "https://via.placeholder.com/300",
    },
    {
      id: 4,
      title: "Post 4",
      description: "Descrição do Post 4",
      image: "https://via.placeholder.com/300",
    },
    {
      id: 5,
      title: "Post 5",
      description: "Descrição do Post 5",
      image: "https://via.placeholder.com/300",
    },
    {
      id: 6,
      title: "Post 6",
      description: "Descrição do Post 6",
      image: "https://via.placeholder.com/300",
    },
  ];

  const onChange = (key) => {
    setCurrentTab(key);
  };
  const items = [
    {
      key: '1',
      label: 'Todos os livros',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: 'Os meus livros',
      children: 'Content of Tab Pane 2',
    },
  ];

  const timeToUpdateBook = async (book) => {
    let newFileList = [];
    console.log(book);
    if (book.image1) {
      let file1 = await urlToFile("http://localhost:8000/books/" + book.image1, book.image1);
      newFileList.push({
        uid: '0',
        name: book.image1,
        status: 'done',
        url: "http://localhost:8000/books/" + book.image1, // Preserve the URL for preview
        thumbUrl: "http://localhost:8000/books/" + book.image1,
        originFileObj: file1, // Add the actual File object
      })
    }
    if (book.image2) {
      let file2 = await urlToFile("http://localhost:8000/books/" + book.image2, book.image2);
      newFileList.push({
        uid: '-1',
        name: book.image2,
        status: 'done',
        url: "http://localhost:8000/books/" + book.image2,
        thumbUrl: "http://localhost:8000/books/" + book.image2,
        originFileObj: file2, // Add the actual File object
      })
    }
    if (book.image3) {
      let file3 = await urlToFile("http://localhost:8000/books/" + book.image3, book.image3);
      newFileList.push({
        uid: '-1',
        name: book.image3,
        status: 'done',
        url: "http://localhost:8000/books/" + book.image3,
        thumbUrl: "http://localhost:8000/books/" + book.image3,
        originFileObj: file3, // Add the actual File object
      })
    }
    book.updatedFileList = newFileList;

    updateForm.setFieldsValue({ 
      id: book?.id,
      name: book?.name, 
      type: book?.type, 
      phone: book?.phone, 
      email: book?.email, 
      description: book?.description, 
      author: book?.author, 
      image: book?.updatedFileList, 
      isbn: book?.isbn, 
      editor: book?.editor , 
      publish_date: book?.publish_date  
    });
    setBookToUpdate(book);
  }

  const showDetails = (book) => {
    setBookToShow(book);
  }
  useEffect(() => {
    console.log(bookToUpdate);
    if (Object.keys(bookToUpdate).length > 0) {
      setUpdateBook(true);
    }
  }, [bookToUpdate]);

    const handleChangeFileList = ({ fileList }) => setFileList(fileList);


  useEffect(() => {
    if (Object.keys(bookToShow).length > 0) {
      setShowBook(true);
    }
  }, [bookToShow]);

  const urlToFile = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
};

  const setValuesInForm = async (data) => {
      let filename = ''; // Extract filename from URL
      let file = {};
      let image = [];

      if (data?.cover?.medium) {
        filename = data.cover.medium.split('/').pop(); // Extract filename from URL
        file = await urlToFile(data.cover.medium, filename);

        image.push({
            uid: `0`, // Unique identifier
            name: file.name,
            status: 'done', // Mark as uploaded
            url: data.cover.medium, // Preserve the URL for preview
            originFileObj: file, // Add the actual File object
          }) 
      }

      form.setFieldsValue({
          name: data.title,
          author: data.authors.map(author => author.name).join(', '),
          image: image,
          publish_date: data.publish_date,
          editor: data.publishers.map(publisher => publisher.name).join(', '),
        });
  }

  useEffect(() => {
    if (Object.keys(bookKey).length > 0) {
      setValuesInForm(bookKey);
    }
  }, [bookKey]);

  const onSearch = async (value, _e, info) => {
    if (value.length == 0) {
        notification.info({
          message: `ERRO!`,
          description: "Por favor, introduza o ISBN.",
        });
        return;
    }
    try {
      const response = await fetch(`https://openlibrary.org/api/books?bibkeys=ISBN:${value}&format=json&jscmd=data`);
      if (!response.ok) {
        throw new Error('Erro ao buscar dados do livro');
      }
      const data = await response.json();
      const bookKey = `ISBN:${value}`;
      console.log(data[bookKey]);
      if (data[bookKey]) {
        console.log("aqui");
        setDataBookKey(data[bookKey]);


        // const filename = bookData.cover.medium.split('/').pop(); // Extract filename from URL
        // const file = await urlToFile(bookData.cover.medium, filename);

        // form.setFieldsValue({
        //   name: bookData.title,
        //   author: bookData.authors.map(author => author.name).join(', '),
        //   // image: [{
        //   //   uid: `0`, // Unique identifier
        //   //   name: file.name,
        //   //   status: 'done', // Mark as uploaded
        //   //   url: bookData.cover.medium, // Preserve the URL for preview
        //   //   originFileObj: file, // Add the actual File object
        //   // }],
        //   publish_date: bookData.publish_date,
        //   editor: bookData.publishers.map(publisher => publisher.name).join(', '),
        // });
      } else {
        notification.error({
          message: `ERRO!`,
          description: "Não foi encontrado nenhum livro com o ISBN introduzido.",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <Wrapper>
      <Helmet>
        <title>
          Livros NISPAP | Núcleo de Internos Sociedade Portuguesa de Anatomia
          Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="O Núcleo de Internos está sempre ao seu dispor. Entre em contacto e coloque-nos as suas dúvidas."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Venda e troca de livros"
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Venda e troca de livros",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-contactos@2x.png")}
          imageMobile={require("../../../Assets/Images/img-contactos.png")}
          imageAlt="Contactos"
        />
            {!updateBook && !newBook &&
            <Row justify={"space-between"} align={"middle"}>
              <Col className="col-btns" span={24}>
                <Row justify={'start'} align={"middle"} gutter={[8,8]}>
                  <Col xl={6} xs={24} align="start">
                  {/* { showBook && 
                    <div className="bookname"><b>Detalhes do livro</b> {bookToShow.name}</div>
                  } */}
                  { !showBook && !newBook &&
                  <Row align="middle" >
                  <Switch defaultChecked={myBooks} onChange={(e) => { handleSearch(selectedBook, selectedType, e)}} />&nbsp;&nbsp;&nbsp;<span className="span-switch">Ver apenas os meus livros</span></Row>}
                  </Col>
                  <Col xl={5} xs={24}>
                  { !showBook && 
                        <Select value={selectedType} placeholder="Tipo de publicação" className="select-type" onChange={(e) => {handleSearch(selectedBook, e, myBooks)}}>
                          <Option value="">Todos</Option>
                          <Option value="sell">Venda</Option>
                          <Option value="trade">Troca</Option>
                        </Select>}
                  </Col>
                  <Col xl={9} xs={24}>
                  { !showBook && 
                    <Input
                      prefix={<SearchOutlined style={{ color: "rgb(46,112,146)"}} />}
                      placeholder="Procurar livro"
                      className="h-40"
                      onChange={(e) => {handleSearch(e.target.value, selectedType, myBooks)}}
                    />}
                  </Col>
                  <Col xl={4} xs={24} type="flex" align="end">
                    { !showBook && 
                    <Button
                      className="new-btn h-40"
                      onClick={() => {
                        form.setFieldsValue({
                          email: session_storage.all_data.user_session.email,
                          phone: session_storage.all_data.user_session.telefone,
                        })
                        setNewBook(true)}
                        }
                    >
                      <Space>
                        {<PlusOutlined />}
                        Nova publicação
                      </Space>
                    </Button>}
                  </Col>
                </Row>
              </Col>
              </Row>
              }
          {newBook && !showBook && (
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={submitForm}
                >
                  <Card
                    className="header-solid mb-24 new-book b-blue mt-10 pt-10"
                    title={
                      <>
                        <Row justify={"space-between"} align={"middle"}>
                          <Col className="text-card" xl={20} xs={24}>
                            <h6 className="font-semibold">Criar nova publicação</h6>
                            <p className="mb-0 t-wrap">
                              Preencha os campos abaixo para poder vender/trocar o seu livro.
                            </p>
                          </Col>
                          <Col className="col-btns" xl={4} xs={24}>
                            <Space>
                              <Button className="cancel" onClick={onCancel}>
                                Cancelar
                              </Button>
                              <Form.Item noStyle>
                                <Button
                                  className="new-btn"
                                  htmlType="submit"
                                >
                                  Guardar
                                </Button>
                              </Form.Item>
                            </Space>
                          </Col>
                        </Row>
                      </>
                    }
                  >
                    <Row gutter={[16,8]} wrap>
                      <Col span={24}>
                        <Space>
                          <p className="text-card mb-0">Para preencher de forma mais rápida os dados do seu livro, introduza o ISBN do respetivo e clique em procurar. </p>
                          
                        </Space>
                      </Col>
                    </Row>
                    <Row gutter={[16,8]} wrap>
                          <Col xl={6} xs={24}>
                            <Form.Item
                              label="ISBN"
                              name="isbn"
                              rules={[
                                { required: false, },
                              ]}
                            >
                              <Input placeholder="ISBN" className="h-40 d-none" />
                              <Search className="search-input w-100"
                                placeholder="Procurar livro"
                                onSearch={onSearch}
                                onChange={(e) => setISBN(e.target.value)}
                                style={{
                                  width: 200,
                                }}
                              />
                            </Form.Item>
                          </Col>
                      <Col xl={9} xs={24}>
                        <Form.Item
                          label="Nome do livro"
                          name="name"
                              rules={[
                                { required: true,
                                  message: 'O nome do livro é obrigatório' },
                              ]}
                        >
                          <Input placeholder="Nome do livro" className="h-40" />
                        </Form.Item>
                      </Col>
                      <Col xl={9} xs={24}>
                        <Form.Item
                          label="Autor"
                          name="author"
                              rules={[
                                { required: true,
                                  message: 'O autor é obrigatório' },
                              ]}
                        >
                          <Input placeholder="Autor"  className="h-40"/>
                        </Form.Item>
                      </Col>
                      </Row>
                      <Row gutter={[16,8]} wrap>
                      <Col xl={16} xs={24}>
                        <Row gutter={[16,8]} wrap>
                          <Col span={24}>
                            <Form.Item
                              label="Descrição"
                              name="description"
                              rules={[
                                { required: true,
                                  message: 'A descrição é obrigatória' },
                              ]}
                            >
                              <TextArea placeholder="Descrição" rows={4}/>
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Editora"
                              name="editor"
                            >
                              <Input placeholder="Editora" className="h-40" />
                            </Form.Item>
                          </Col>
                      <Col xl={8} xs={24}>
                        <Form.Item
                          label="Tipo de Publicação"
                          name="type"
                              rules={[
                                { required: true,
                                  message: 'O tipo de publicação é obrigatório' },
                              ]}
                        >
                          <Select placeholder="Tipo de publicação" className="select-type">
                            <Option value="sell">Venda</Option>
                            <Option value="trade">Troca</Option>
                          </Select>
                        </Form.Item>
                          </Col>
                      <Col xl={4} xs={24}>
                        <Form.Item
                          label="Data"
                          name="publish_date"
                              rules={[
                                { required: false  },
                              ]}
                        >
                          <Input placeholder="Data"  className="h-40"/>
                        </Form.Item>
                      </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Email*"
                              name="email"
                              rules={[
                                { required: true,
                                message: "O email é obrigatório" },
                                { type: "email", warningOnly: true, meesage: "Introduza um email válido" },
                              ]}
                            >
                              <Input placeholder="Email" className="h-40" />
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Número de telemóvel"
                              name="phone"
                            >
                              <Input placeholder="Número de telemóvel" className="h-40"/>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={8} xs={24}>
                        <Form.Item
                          label="Imagens"
                          name="image"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                              rules={[
                                { required: true,
                                  message: 'Introduza pelo menos uma imagem' },
                              ]}
                        >
                          <Upload
                            {...prop}
                            name="image"
                            listType="picture"
                            accept={"image/*"}
                            className="w-100"
                            maxCount={3}
                            fileList={fileList}
                            onChange={handleChangeFileList}
                          >
                            <Button icon={<UploadOutlined />}
                            className="h-40 w-100">
                              Clique para fazer upload (máx. 3 imagens)
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Form>
          )}

        {updateBook && !showBook && (
              <Form
                form={updateForm}
                layout="vertical"
                onFinish={submitUpdateForm}
                initialValues={{ 
                  id: bookToUpdate?.id,
                  nameToUpdate: bookToUpdate?.name, 
                  type: bookToUpdate?.type, 
                  phone: bookToUpdate?.phone, 
                  email: bookToUpdate?.email, 
                  description: bookToUpdate?.description, 
                  author: bookToUpdate?.author, 
                  image: bookToUpdate?.updatedFileList }}
              >
                <Card
                  className="header-solid mb-24 new-book b-blue mt-10"
                  title={
                    <>
                      <Row justify={"space-between"} align={"middle"}>
                        <Col className="text-card">
                          <h6 className="font-semibold">Editar publicação</h6>
                          <p className="mb-0">
                            Preencha os campos abaixo para editar os dados relativos ao seu livro.
                          </p>
                        </Col>
                        <Col className="col-btns">
                          <Space>
                            <Button className="cancel" onClick={onCancelUpdate}>
                              Cancelar
                            </Button>
                            <Form.Item noStyle>
                              <Button
                                className="new-btn"
                                htmlType="submit"
                              >
                                Guardar
                              </Button>
                            </Form.Item>
                          </Space>
                        </Col>
                      </Row>
                    </>
                  }
                >
                 <Row gutter={[16,8]} wrap>
                          <Col xl={6} xs={24}>
                            <Form.Item
                            className="d-none"
                              name="id"
                              rules={[
                                { required: false, },
                              ]}
                            >
                              <Input placeholder="id" className="h-40 d-none" />
                            </Form.Item>
                            <Form.Item
                              label="ISBN"
                              name="isbn"
                              rules={[
                                { required: true, },
                              ]}
                            >
                              <Input placeholder="ISBN" className="h-40" />
                            </Form.Item>
                          </Col>
                      <Col xl={9} xs={24}>
                        <Form.Item
                          label="Nome do livro"
                          name="name"
                              rules={[
                                { required: true,
                                  message: 'O nome do livro é obrigatório' },
                              ]}
                        >
                          <Input placeholder="Nome do livro" className="h-40" />
                        </Form.Item>
                      </Col>
                      <Col xl={9} xs={24}>
                        <Form.Item
                          label="Autor"
                          name="author"
                              rules={[
                                { required: true,
                                  message: 'O autor é obrigatório' },
                              ]}
                        >
                          <Input placeholder="Autor"  className="h-40"/>
                        </Form.Item>
                      </Col>
                      </Row>
                      <Row gutter={[16,8]} wrap>
                      <Col xl={16} xs={24}>
                        <Row gutter={[16,8]} wrap>
                          <Col span={24}>
                            <Form.Item
                              label="Descrição"
                              name="description"
                              rules={[
                                { required: true,
                                  message: 'A descrição é obrigatória' },
                              ]}
                            >
                              <TextArea placeholder="Descrição" rows={4}/>
                            </Form.Item>
                          </Col>
                          <Col  xl={12} xs={24}>
                            <Form.Item
                              label="Editora"
                              name="editor"
                            >
                              <Input placeholder="Editora" className="h-40" />
                            </Form.Item>
                          </Col>
                      <Col  xl={8} xs={24}>
                        <Form.Item
                          label="Tipo de Publicação"
                          name="type"
                              rules={[
                                { required: true,
                                  message: 'O tipo de publicação é obrigatório' },
                              ]}
                        >
                          <Select placeholder="Tipo de publicação" className="select-type">
                            <Option value="sell">Venda</Option>
                            <Option value="trade">Troca</Option>
                          </Select>
                        </Form.Item>
                          </Col>
                      <Col xl={4} xs={24}>
                        <Form.Item
                          label="Ano"
                          name="publish_date"
                              rules={[
                                { required: false  },
                              ]}
                        >
                          <Input placeholder="Ano"  className="h-40"/>
                        </Form.Item>
                      </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Email*"
                              name="email"
                              rules={[
                                { required: true,
                                message: "O email é obrigatório" },
                                { type: "email", warningOnly: true, meesage: "Introduza um email válido" },
                              ]}
                            >
                              <Input placeholder="Email" className="h-40" />
                            </Form.Item>
                          </Col>
                          <Col span={12} xs={24}>
                            <Form.Item
                              label="Número de telemóvel"
                              name="phone"
                            >
                              <Input placeholder="Número de telemóvel" className="h-40"/>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} xs={24}>
                        <Form.Item
                          label="Imagens"
                          name="image"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                              rules={[
                                { required: true,
                                  message: 'Introduza pelo menos uma imagem' },
                              ]}
                        >
                          <Upload
                            {...prop}
                            name="image"
                            listType="picture"
                            accept={"image/*"}
                            className="w-100"
                            maxCount={3}
                            fileList={fileList}
                            onChange={handleChangeFileList}
                          >
                            <Button icon={<UploadOutlined />}
                            className="h-40 w-100">
                              Clique para fazer upload (máx. 3 imagens)
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                </Card>
              </Form>
        )}

        { showBook && 
        
          <Row gutter={[8,16]} className="card-details" align={'top'}>
            
            <Col xl={7} xs={24}>
            { bookToShow.image1 && bookToShow.image2 &&
                  <Carousel autoplay className="carousel" arrows>
                  {bookToShow.image1 && 
                    <div>
                      <img  alt={bookToShow.name} src={"http://localhost:8000/books/" + bookToShow.image1} />
                    </div>
                    }
                    {bookToShow.image2 && 
                    <div>
                      <img  alt={bookToShow.name} src={"http://localhost:8000/books/" + bookToShow.image2} />
                    </div>
                    }
                    {bookToShow.image3 && 
                    <div>
                      <img  alt={bookToShow.name} src={"http://localhost:8000/books/" + bookToShow.image3} />
                    </div>
                    }
                  </Carousel>}
                  { bookToShow.image1 && !bookToShow.image2 &&
                      <img  alt={bookToShow.name} src={"http://localhost:8000/books/" + bookToShow.image1} />}
            </Col>
            <Col xl={17} xs={24}>
            <Button
                      className="new-btn h-40 fl-right"
                      onClick={() => setShowBook(false)}
                    >
                      <Space>
                        {<ArrowLeftOutlined />}
                        Voltar à listagem
                      </Space>
                    </Button>
              <p className="bookdetails"><b>Nome do Livro | ISBN</b></p><p className="bookdetails">{bookToShow.name} | {(bookToShow.isbn) ? bookToShow.isbn : '-'}</p>
              <Divider />
              <p className="bookdetails"><b>Autor { (bookToShow.publish_date ? '| Data' : '') }</b></p><p className="bookdetails"> {bookToShow.author} { (bookToShow.publish_date ? " | " + bookToShow.publish_date : '') }</p>
              <Divider />
              {bookToShow.editor && 
                <>
                  <p className="bookdetails"><b>Editor</b></p><p className="bookdetails"> {bookToShow.editor}</p>
                  <Divider />
                </>
              }
              <p className="bookdetails"><b>Descrição</b></p><p className="bookdetails"> {bookToShow.description}</p>
              <Divider />
              <p className="bookdetails"><b>Tipo de publicação</b></p><p className="bookdetails"> {(bookToShow.type == 'sell') ? 'Venda' : 'Troca'}</p>
              <Divider />
              <p className="bookdetails"><b>Dados do vendedor</b></p><p className="bookdetails"> {(bookToShow.userName) ? <><u>Nome</u> {bookToShow.userName}</> : <></>} {(bookToShow.email) ? <><u>Email</u> {bookToShow.email}</> : <></>} {(bookToShow.email && bookToShow.phone) && " | " } {(bookToShow.phone) ? <><u>Telemóvel</u> {bookToShow.phone}</> : <></>}</p>
            </Col>
          </Row>
        }
          

          
          {bookstoShow.length == 0 && !showBook && !updateBook && (
            <Card className="mt-10">
              <Empty className="empty-box" description="Não foram encontrados livros" />
              </Card>
          )}
        <div className="mt-20">
          <Row gutter={[16, 16]}>
            {!updateBook && !showBook && bookstoShow.map(book => (
              <Col key={book.id} xs={24} sm={12} md={8}>
                <Card
                  hoverable
                  cover={<><div className="absolute-tag"><Tag color="rgb(248, 132, 54)">{(book.type == 'sell') ? 'VENDA' : 'TROCA'}</Tag></div>
                  { book.image1 && book.image2 &&
                  <Carousel autoplay className="carousel" arrows>
                  {book.image1 && 
                    <div>
                      <img  alt={book.name} src={"http://localhost:8000/books/" + book.image1} />
                    </div>
                    }
                    {book.image2 && 
                    <div>
                      <img  alt={book.name} src={"http://localhost:8000/books/" + book.image2} />
                    </div>
                    }
                    {book.image3 && 
                    <div>
                      <img  alt={book.name} src={"http://localhost:8000/books/" + book.image3} />
                    </div>
                    }
                  </Carousel>}
                  { book.image1 && !book.image2 &&
                      <img  alt={book.name} src={"http://localhost:8000/books/" + book.image1} />}
                  </>}
                >
                  <Row align={'bottom'}>
                    <Col xs={24} lg={12} align="left">
                      <Meta title={book.name} description={book.author} />
                    </Col>
                    <Col xs={24} lg={12} align="right">
                    <Space>
                      { (book.user_id == session_storage.all_data.user_session.id) &&
                        <Button className="see-more-btn edit-btn" onClick={() => timeToUpdateBook(book)}><EditOutlined /></Button>
                      }
                      <Button className="see-more-btn edit-btn" onClick={() => showDetails(book)}><EyeOutlined /></Button>
                    </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>

    </Wrapper>
  );
}
