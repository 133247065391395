import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Image } from "antd";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin: 7.813vw 0 4.792vw;
  overflow-x: hidden;

  > .content {
    padding-bottom: 4vw;
  }

  .image {
    margin-left: auto;
    max-width: 32.292vw;
  }

  img {
    width: 100%;
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    text-align: left;
    font-size: 16px;
    font-weight: medium;
    line-height: 1.6vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  a.orange {
    color: ${(props) => props.theme.orange};
    text-decoration: underline;
    transition: 0.3s ease-out;
  }

  a.orange:hover {
    text-decoration: none;
  }

  .topo {
    width: 100%;

    p a {
      display: initial;
    }
  }

  .ant-image-mask-info {
    display: none;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    p,
    li {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  
  opacity: 1;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SliderContent = styled.div`
  overflow: hidden;

  .slick-list,
  .slick-track {
    width: 100%;
  }

  .slick-track {
    display: flex;
    gap: 8px;
  }

  .slick-slider {
    position: relative;
  }

  .slick-arrow {
    position: absolute;
    bottom: -50px;
    font-size: 16px;
  }

  .slick-prev {
    left: calc(50% - 25px);
  }

  .slick-next {
    left: calc(50% + 25px);
  }

  .slick-arrow path {
    fill: #bcbcbc;
    transition: 0.3s ease;
    cursor: pointer;
  }

  .slick-arrow:hover path {
    fill: #f88436;
  }

  @media (max-width: 992px) {
    height: auto;
    padding-bottom: 80px;

    .slick-arrow {
      font-size: 24px;

      path {
        fill: rgb(248, 132, 54);
      }
    }

    img {
      max-height: 400px;
    }
  }
`;

const Picture = styled.picture`
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};

  @media (min-width: 992px) {
    /*
    img {
      max-height: 27.9166vw;
    }
    */
  }

  @media (max-width: 992px) {
    &.solo {
      margin: auto;
      min-width: 75vw;
      max-width: 75vw;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 55px;

  &.maxHeight .slick-slide img {
    height: 450px;
    object-fit: contain;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 30px;

    &.maxHeight .slick-slide img {
      height: 300px;
    }
  }
`;

const GridNormal = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 60px;

  @media (min-width: 992px) {
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  @media (min-width: 1920px) {
    grid-template-columns: 400px 1fr;

    li {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export default function Historia(props) {
  const settings = {
    className: "center card-swipe",
    arrows: true,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log("swipe");
    },
  };

  const SidebarMenuLinks = [
    {
      title: "História",
      link: "/spap/historia",
    },
    {
      title: "Presidentes",
      link: "/spap/presidentes",
    },
    {
      title: "Congressos...",
      link: "/spap/congressos",
    },
  ];

  const ListPictures = [
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-01@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-01.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-02@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-02.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-03@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-03.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-04@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-04.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-05@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-05.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-06@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-06.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-07@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-07.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-08@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-08.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-09@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-09.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-10@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-10.png"),
      imageAlt: "O primeiro congresso",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-primeiro-congresso-11@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-primeiro-congresso-11.png"),
      imageAlt: "O primeiro congresso",
    },
  ];

  const ListPictures2 = [
    {
      imageDesktop: require("../../../Assets/Images/img-historia-congresso-sociedade-01@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-congresso-sociedade-01.png"),
      imageAlt: "O congresso sociedade",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-congresso-sociedade-02@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-congresso-sociedade-02.png"),
      imageAlt: "O congresso sociedade",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-congresso-sociedade-03@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-congresso-sociedade-03.png"),
      imageAlt: "O congresso sociedade",
    },
    {
      imageDesktop: require("../../../Assets/Images/img-historia-congresso-sociedade-04@2x.png"),
      imageMobile: require("../../../Assets/Images/img-historia-congresso-sociedade-04.png"),
      imageAlt: "O congresso sociedade",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          História | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="A nossa sociedade tem já uma longa história cheia de acontecimentos dignos de destaque. Contamos com todos para os poucos a divulgarmos aqui e manter vivo este legado."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <FlexInRow gap="4.792vw">
        <Container className="content">
          <PageTitle
            title="História"
            path={[
              {
                text: "Homepage",
                link: "/",
              },
              {
                text: "SPAP",
                link: "/spap",
              },
              {
                text: "História e Estatutos",
              },
            ]}
            imageDesktop={require("../../../Assets/Images/img-titulo-historia@2x.png")}
            imageMobile={require("../../../Assets/Images/img-titulo-historia@2x.png")}
            imageAlt="História e Estatutos"
            sidebar={
              <SidebarMenu navItens={SidebarMenuLinks} className="sidebar" />
            }
          />

          <FlexInRow gap="80px">
            <FlexInColumn gap="60px" className="topo" mobile="column">
              <Picture className="solo" width="250px">
                <source
                  srcSet={require("../../../Assets/Images/img-historia-top@2x.png")}
                  media="(min-width: 990px)"
                />
                <img
                  src={require("../../../Assets/Images/img-historia-top.png")}
                  alt="História"
                />
              </Picture>
              <div>
                <FlexInRow gap="5px">
                  <p>
                    A nossa sociedade tem já uma longa história cheia de
                    acontecimentos dignos de destaque.{" "}
                  </p>
                  <p>
                    Contamos com todos para os poucos a divulgarmos aqui e
                    manter vivo este legado.{" "}
                  </p>
                  <p>
                    <b>Tem uma história da SPAP para contar?</b>
                  </p>
                  <p>
                    Gostávamos de ter a sua contribuição!{" "}
                    <Link to="/spap/contactos" className="orange">
                      Contacte-nos.
                    </Link>
                  </p>
                </FlexInRow>
              </div>
            </FlexInColumn>

            <Title>
              1963 - A Criação Da Sociedade Portuguesa De Anatomia Patológica
            </Title>

            <GridNormal>
              <Picture className="solo">
                <source
                  srcSet={require("../../../Assets/Images/img-historia-principal@2x.png")}
                  media="(min-width: 990px)"
                />
                <img
                  src={require("../../../Assets/Images/img-historia-principal.png")}
                  alt="Amândio Tavares"
                />
              </Picture>
              <FlexInRow gap="10px">
                <FlexInColumn gap="5.208vw" mobile="column">
                  <FlexInRow gap="2.604vw">
                    <p>
                      A anatomia patológica, enquanto especialidade médica
                      autónoma, só começou a existir em Portugal nos anos
                      sessenta do século passado, vivendo até então associada à
                      especialidade análises clínicas. É em 1963, ano do
                      centenário do ensino da cadeira de anatomia patológica na
                      Faculdade de Medicina em Lisboa, que os
                      anatomo-patologistas portugueses se unem e constituem a
                      Sociedade Portuguesa de Anatomia Patológica, cujo primeira
                      Assembleia Geral ocorre em Lisboa e elege a primeira
                      Direção que ficou assim constituída:
                    </p>
                  </FlexInRow>
                </FlexInColumn>
                <ul>
                  <li>Presidente - Prof. Amândio Tavares, Porto</li>
                  <li>Vice-Presidente - Prof Jorge da Silva Horta, Lisboa</li>
                  <li>Secretário - Prof. Renato Trincão, Coimbra</li>
                  <li>
                    Vogais: Drs. Amilcar Gonçalves e Oliveira Firmo, Lisboa
                  </li>
                </ul>
                <p>
                  Esta primeira Direção, plena de entusiasmo, vitabilidade e
                  muito saber, passou a realizar três reuniões anuais, uma em
                  Lisboa, uma em Coimbra e uma no Porto, cujo modelo integrava
                  três momentos: revisão teórica e aprofundamento do tema
                  definido para a reunião: apresentação e discussão de casos;
                  reunião administrativa.
                </p>
                <p>
                  <b>Autoria:</b> Prof. Carlos Lopes
                </p>
              </FlexInRow>
            </GridNormal>

            <Title>
              1968 - O Primeiro Congresso Nacional De Anatomia Patológica
            </Title>

            <Grid className="right">
              <FlexInRow gap="20px">
                <p>
                  Desde a criação da SPAP que começou a desenvolver-se a ideia
                  da realização de um Congresso Nacional com uma dimensão
                  nacional que integrasse patologistas de Portugal, mas também
                  de Angola e Moçambique que então eram consideradas{" "}
                  <i>“províncias ultramarinas”</i> e nas quais se iniciara o
                  ensino universitário. O então Dr. Oliveira Torres foi o grande
                  entusiasta e protagonista da ideia que encontrou no Prof.
                  Daniel Serrão, que se encontrava a cumprir o serviço militar
                  em Luanda, igual determinação e vontade.
                </p>
                <p>
                  Assim, o primeiro Congresso Nacional de Anatomia Patológica de
                  Portugal veio a realizar-se, com alguma pompa, em Moçambique e
                  em Angola, em outubro e novembro de 1968. Em Moçambique, na
                  antiga cidade de Lourenço Marques, decorreu de 26 de Outubro a
                  2 de Novembro. Em Angola, na cidade de Luanda, decorreu de 4 a
                  7 de novembro. Moçambique teve uma participação mais ativa que
                  Angola devido a duas razões fundamentais: A então cidade de
                  Lourenço Marques tinha já uma longa tradição de anatomia
                  patológica em resultado do trabalho meritório do Prof. Manuel
                  Prates que aí organizou a partir de 1950 o Registo de Cancro
                  de Base Populacional que permitiu verificar a alta incidência
                  de hapatocarcinoma, de sarcoma de Kaposi e de carcinoma
                  espinocelular do esófago e da bexiga na região. Após a morte
                  prematura e brutal do Prof. Prates num acidente de viação, o
                  Prof. Oliveira Torres deu continuidade a toda a atividade
                  então existente, criando uma Escola que ainda hoje permanece
                  com pujança e qualidade através da Prof. Carla Carrilho cuja
                  carreira profissional e académica se processou no IPATIMUP,
                  Hospital de S. João e também no IPO do Porto.
                </p>
                <p>
                  Assim, no 1º Congresso Nacional de Anatomia Patológica,
                  Moçambique e a escola do Prof. Oliveira Torres foram
                  protagonistas. O Congresso contou com 59 comunicações, 32 em
                  Lourenço Marques e 27 em Luanda e teve quatro relatórios,
                  todos sobre a fibrose em doenças hepáticas: (1) Fibroses
                  hepáticas, excluindo as africanas a cargo do Prof. Jorge
                  Horta, de Lisboa; (2) Alguns aspectos da patologia da cirrose
                  hepática em Portugal metropolitano, a cargo da Dra. Amélia
                  Batista, Lisboa; (3) Cirroses hepáticas experimentais, a cargo
                  do Prof. Daniel Serrão, Porto; (4) e Lesões Hepáticas no
                  Africano de Moçambique. Suas causas e aspetos
                  histopatológicos, a cargo do Prof. Oliveira Torres, de
                  Moçambique.
                </p>
                <p>
                  <b>Autoria:</b> Prof. Carlos Lopes
                </p>
              </FlexInRow>

              <SliderContent>
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                      console.log(
                        `current index: ${current}, prev index: ${prev}`
                      ),
                  }}
                >
                  <Slider {...settings}>
                    {ListPictures.map((item, index) => {
                      return (
                        <Image
                          className="slider-picture"
                          src={item.imageDesktop}
                          alt={item.imageAlt}
                        />
                      );
                    })}
                  </Slider>
                </Image.PreviewGroup>
              </SliderContent>
            </Grid>

            <Title>
              1989 - O Congresso Da Sociedade Europeia De Patologia No Porto
            </Title>

            <Grid className="left maxHeight">
              <SliderContent>
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                      console.log(
                        `current index: ${current}, prev index: ${prev}`
                      ),
                  }}
                >
                  <Slider {...settings}>
                    {ListPictures2.map((item, index) => {
                      return (
                        <Image
                          className="slider-picture"
                          src={item.imageDesktop}
                          alt={item.imageAlt}
                        />
                      );
                    })}
                  </Slider>
                </Image.PreviewGroup>
              </SliderContent>
              <FlexInRow gap="20px">
                <p>
                  O Congresso da Sociedade Europeia de Patologia (ESP em
                  inglês), realizado no Porto em Setembro de 1989, foi o XII
                  Congresso da ESP. A participação foi um recorde notável pois
                  tivemos mais de 1300 inscritos, em contraste com um máximo de
                  cerca de oito centenas de patologistas até ao 11º Congresso.
                  Levar o Congresso para o Porto ficou a dever-se sobretudo ao
                  envolvimento do Prof. Daniel Serrão e do  Prof . Jan V
                  Johannessen, Presidente da ESP de 1983 a 1985, responsável
                  pelo reforço da ligação entre a Europa e Portugal, e do Prof.
                  António  Llombart Bosch, Presidente da ESP de 1987 a 1989.
                  Neste <i>“movimento”</i> pro-Porto tínhamos ido de autocarro,
                  em 1987, à reunião preparatória  do Congresso, organizada pelo
                  Prof. Llombart Bosch em Valencia.  
                </p>
                <p>
                  A organização do Congresso foi assegurada por um grupo
                  entusiástico de internos de anatomia patológica e de jovens, e
                  menos jovens, patologistas da Faculdade de Medicina e do
                  Hospital de S. João (Fig.1).{" "}
                </p>
                <p>
                  O Congresso do Porto ficou baptizado como o{" "}
                  <i>“5 P… Congress”</i> em honra de Portugal, Porto, Port Wine,
                  Pleasure., … and Pathology (Fig.2). Em tom de graça, chegámos
                  a salientar, num Editorial da Pathology Research and Practice
                  de 1989, que o Congresso mereceria um sexto P se comida se
                  escrevesse <i>“Phood”</i> (Fig.3). Este ambiente
                  simpatiquíssimo de alegria e competência anátomo-patológica
                  foi uma constante ao longa da semana, desde o texto de
                  Introdução <i>“What I would do if I were you..:”</i> (Fig.4)
                  até à cerimónia de encerramento, apoteótica, passando por
                  sessões de apresentação e discussão muito competitivas, até
                  uma vez à Noite e com Vinho do Porto…{" "}
                </p>
                <p>
                  A parte científica do Congresso foi excepcional e a nossa
                  gente revelou-se imbatível em termos humanos e profissionais.
                  Entretanto, estiveram vários campeões europeus, alguns
                  americanos, do norte, centro e sul, e um par de asiáticos –
                  uma verdadeira lista de{" "}
                  <i>“Quem é quem em Patologia no Mundo”</i> que nunca tínhamos
                  tido em Portugal, mas o herói dos heróis foi o Prof. Juan
                  Rosai de quem temos muitas, muitas saudades (Fig.1).{" "}
                </p>
                <p>
                  <b>Autoria:</b> Prof. Sobrinho Simões
                </p>
              </FlexInRow>
            </Grid>
          </FlexInRow>
        </Container>
      </FlexInRow>
    </Wrapper>
  );
}
